import { callGet, callPost, callUpdate } from '../../lib/api-private';

export const sendPurchaseOrderToSupplier = async (
  accessToken: string,
  recipient: string,
  subject: string,
  content: string
) => {
  const url = 'communication/sendPurchaseOrder';
  const data = {
    recipient,
    subject,
    content,
    medium: 'email',
  };
  const dataPromise = callPost(accessToken, url, data);
  return await dataPromise;
};
