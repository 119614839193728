import { useAuth0 } from '@auth0/auth0-react';
import { PaymentRequestDto } from 'generated-client/model/payment-request-dto';
import { useEffect, useState } from 'react';
import { sendPurchaseOrderToSupplier } from 'services/communication';

export const useSendEmailHook = () => {
  const [paymentRequest, setPaymentRequest] =
    useState<PaymentRequestDto | null>(null);
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently()
      .then((token: string) => {
        setAccessToken(token);
      })
      .catch((error: any) => {
        setError(error.message);
      });
  }, []);

  useEffect(() => {
    console.log('payment request', paymentRequest);
    const sendEmailToSupplier = async () => {
      try {
        setSendingEmail(true);
        setError('');
        setSuccess(false);
        const result = await sendPurchaseOrderToSupplier(
          accessToken,
          paymentRequest.supplierId,
          'Purchase Order',
          paymentRequest.id
        );
        setSuccess(true);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setSendingEmail(false);
        setPaymentRequest(null);
      }
    };

    if (paymentRequest && accessToken) {
      sendEmailToSupplier();
    }
  }, [paymentRequest, accessToken]);

  return {
    setPaymentRequest,
    sendingEmail,
    sendingSuccess: success,
    error,
  };
};
