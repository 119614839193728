import {
  Box,
  VStack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  Select,
  Flex,
  Checkbox,
  Button,
  FormControl,
  InputGroup,
  InputLeftAddon,
  FormLabel,
  useBreakpointValue,
  HStack,
} from '@chakra-ui/react';

import { Fragment, useEffect, useRef, useState } from 'react';
import Card from 'components/card/Card';
import { useAuth0 } from '@auth0/auth0-react';
import ConfirmationModal from './components/modals/ConfirmationModal';
import { PlanSelectConfirmationModal } from './components/modals/PlanSelectConfirmationModal';
import { createPaymentRequest } from '../../../services/payment-request';
import { CreateDirectPaymentRequest } from '../../../generated-client/model/create-direct-payment-request';
import ProofOfUserPaymentModal from './components/modals/ProofOfUserPaymentModal';
import { useNavigate } from 'react-router-dom';
import { uploadFile } from '../../../services/file';
import {
  findSubscriptionPlanById,
  formatCurrency,
} from '../../../lib/utilities';
import { GetStartedDirectPayments } from './components/GetStarted';
import { CompanyDto } from '../../../generated-client/model/company-dto';
import { getCompany } from '../../../services/company';
import KycStatusEnum = CompanyDto.KycStatusEnum;
import { getUser } from '../../../services/user';
import { UserDto } from '../../../generated-client/model/user-dto';
import RoleEnum = UserDto.RoleEnum;
import { getExchangeRate } from '../../../services/finance';
import { ExchangeRateDto } from '../../../generated-client/model/exchange-rate-dto';
import { DirectPaymentDto } from '../../../generated-client/model/direct-payment-dto';
import AmountCurrencyEnum = DirectPaymentDto.AmountCurrencyEnum;
import { RepaymentTerms } from './components/RepaymentsTerms';
import { CreatePaymentRequestRequest } from '../../../generated-client/model/create-payment-request-request';
import PricingBox from './components/Pricing';
import { getPricingPlans } from 'services/pricing/pricing';
import { PricingPlanDto } from 'generated-client/model/pricing-plan-dto';
import PricingPayModal from './components/modals/PricingPayModal';
import { SourcingItemDto } from 'generated-client/model/sourcing-item-dto';
import { useTranslation } from 'react-i18next';
import {
  createSourcingRequest,
  getAllSourcingRequestsByCompanyId,
} from 'services/sourcing';
import { HSeparator } from '../../../components/separator/Separator';
import { SourcingRequestDto } from 'generated-client/model/sourcing-request-dto';
import { SourcingRequestProFormaInvoiceDto } from 'generated-client/model/sourcing-request-pro-forma-invoice-dto';
import TableComponent from './components/Table';
import InvoicesModal from './components/modals/InvoicesModal';
import SourcingSuccessModal from './components/modals/SourcingSuccessModal';
import ShippingModal from './components/modals/ShippingModal';
import CardLayout from './components/CardLayout';
import { PaymentOptionsBlock } from 'components/PaymentOptionsBlock';

const flexPayBankDetails = [
  'Account Name: MAMLAKA HUB & SPOKE LIMITED',
  'Bank account number: 55010160018203',
  'Bank/Branch: UBA Kenya Bank Limited, Westland’s Branch',
  'Swift code: UNAFKENA',
];

const mPesaDetails = [
  'Paybill Number: 559900',
  'Account number: 55010160018203',
];

export type RepaymentTermsType = {
  creditAmount: number;
  totalRepay: number;
  balanceToPayNow: number;
  transactionFee: number;
  dueDate: string;
};

export default function UsdPaymentsPage() {
  const navigate = useNavigate();
  const isTableLayout = useBreakpointValue({ base: false, lg: true });
  const { t } = useTranslation();
  const radioGroupRef = useRef(null);

  const [recipientCountry, setRecipientCountry] = useState<string>('');
  const [creditLimit, setCreditLimit] = useState<number>(0);
  const [recipientName, setRecipientName] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [swiftCode, setSwiftCode] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [recipientAddress, setRecipientAddress] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [companyData, setCompanyData] = useState<CompanyDto>();
  const [paymentType, setPaymentType] = useState<string>('bankTransfer');
  const [activeTab, setActiveTab] = useState<string>('supplier');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fxRateSupplier, setFxRateSupplier] = useState<number>(0);
  const [fxRateOther, setFxRateOther] = useState<number>(0);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [, setIsUserAdmin] = useState(false);
  const [currency, setCurrency] = useState('ZAR');
  const fileInputRef = useRef(null);
  const [formError, setFormError] = useState<string | null>(null);
  const [creditAmountError, setCreditAmountError] = useState<string | null>(
    null
  );
  const [tabIndex, setTabIndex] = useState(0); // Default active tab index
  const [amountInputError, setAmountInputError] = useState<string | null>(null);
  const amountInputRefSupplier = useRef(null);
  const amountInputRefOtherRecipient = useRef(null);
  const [showFlexPayForm, setShowFlexPayForm] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [creditAmount, setCreditAmount] = useState<number>(0);
  const [duration, setDuration] = useState('30days');
  const [paymentMethod, setPaymentMethod] =
    useState<CreateDirectPaymentRequest.PaymentMethodEnum>(null);
  const [repaymentTerms, setRepaymentTerms] = useState<RepaymentTermsType>({
    creditAmount: 0,
    totalRepay: 0,
    balanceToPayNow: 0,
    transactionFee: 0,
    dueDate: null,
  });
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const [plans, setPlans] = useState<PricingPlanDto[]>([]);
  const [isPayModalOpen, setIsPayModalOpen] = useState(false);
  const [
    isPlanSelectConfirmationModalOpen,
    setIsPlanSelectConfirmationModalOpen,
  ] = useState(false);
  const [usdToRwf, setUsdToRwf] = useState<number>(0);
  const [isSubscribeLoading, setIsSubscribeLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSourcingRequestSuccessful, setIsSourcingRequestSuccessful] =
    useState(false);
  const [items, setItems] = useState<SourcingItemDto[]>([
    { name: '', quantity: 0, category: 'CONSTRUCTION_MATERIALS' },
  ]);
  const [sourcingRequests, setSourcingRequests] = useState<
    SourcingRequestDto[]
  >([]);
  const [proformaInvoices, setProformaInvoices] = useState<
    SourcingRequestProFormaInvoiceDto[]
  >([]);
  const [isViewInvoicesModalOpen, setIsViewInvoicesModalOpen] = useState(false);
  const [isShippingModalOpen, setIsShippingModalOpen] = useState(false);
  const [proformaInvoiceToPay, setProformaInvoiceToPay] =
    useState<SourcingRequestProFormaInvoiceDto>(null);
  const [isShippingIncluded, setIsShippingIncluded] = useState(true);
  const [modalType, setModalType] = useState<'pay' | 'sourcing'>('sourcing');
  const [payMethod, setPayMethod] = useState<'mobile' | 'bank'>('mobile');
  const [radioGroupWidth, setRadioGroupWidth] = useState(0);

  const updateDividerWidth = () => {
    if (radioGroupRef.current) {
      setRadioGroupWidth(radioGroupRef.current.clientWidth);
    }
  };

  useEffect(() => {
    // attach event listener and update divider width only when the tab selected is pay a supplier
    if (tabIndex === 2) {
      updateDividerWidth();

      // update the box divider width on window resize or focus
      window.addEventListener('resize', updateDividerWidth);
      window.addEventListener('focus', updateDividerWidth);

      // clean up the event listeners
      return () => {
        window.removeEventListener('resize', updateDividerWidth);
        window.removeEventListener('focus', updateDividerWidth);
      };
    }
  }, [tabIndex]);

  const chooseProformaInvoiceToPay = (
    chosenProformaInvoiceToPay: SourcingRequestProFormaInvoiceDto
  ) => {
    setProformaInvoiceToPay(chosenProformaInvoiceToPay);
    setIsViewInvoicesModalOpen(false);
    setIsShippingModalOpen(true);
  };

  const handleShipping = (value: boolean) => {
    setIsShippingIncluded(value);
    setIsShippingModalOpen(false);
    handleTabChange(2);
  };

  const handleTabChange = (index: number) => {
    updateDividerWidth();
    if (index !== 2) {
      setProformaInvoiceToPay(null);
    }
    // You can also execute additional code here if needed
    setTabIndex(index);
    setFormError(null);
    setPaymentType('bankTransfer');

    // Clear the state values here
    setRecipientCountry('');
    if (amountInputRefOtherRecipient.current) {
      amountInputRefOtherRecipient.current.value = ''; // Directly set the input's value to empty
    }
    if (amountInputRefSupplier.current) {
      amountInputRefSupplier.current.value = ''; // Directly set the input's value to empty
    }
    setRecipientName('');
    setAccountNumber('');
    setPhoneNumber('');
    setSwiftCode('');
    setRecipientAddress('');
    setSelectedFile(null);
    setCreditAmount(0);
    setShowFlexPayForm(false);
  };

  const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100;

  useEffect(() => {
    const terms = calculateRepaymentTerms(creditAmount, duration);
    setRepaymentTerms(terms as RepaymentTermsType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditAmount, duration, proformaInvoiceToPay]);

  const validateForm = () => {
    if (activeTab !== 'other' && paymentType === 'flexPay' && !termsAccepted) {
      setFormError('Terms must be accepted');
      return false;
    }

    if (
      activeTab !== 'other' &&
      paymentType === 'flexPay' &&
      creditAmount <= 0
    ) {
      setFormError('Credit amount must be bigger than 0');
      return false;
    }

    if (activeTab !== 'other' && paymentType === 'flexPay') {
      if (creditAmount <= 0) {
        setFormError('Credit amount must be bigger than 0');
        return false;
      }
    }

    setFormError(null);
    return true;
  };

  const validateCreditAmountInput = (val: number) => {
    if (val > creditLimit) {
      setCreditAmountError('Amount cannot be higher than credit limit');
      return false;
    }

    if (
      val >
      (Number(proformaInvoiceToPay?.totalAmountLocal) +
        Number(
          isShippingIncluded ? proformaInvoiceToPay?.shippingEstimateAmount : 0
        )) *
        0.7
    ) {
      setCreditAmountError(
        'Amount cannot be higher than 70% of the total amount'
      );
      return false;
    }
    setCreditAmountError(null);
    return true;
  };

  const openReviewModal = () => {
    if (validateForm()) {
      setIsOpen(true);
    }
  };

  async function fetchData() {
    try {
      const accessToken = await getAccessTokenSilently();
      const data = await getCompany(accessToken);
      const user = await getUser(accessToken);
      const sourcingReqs = await getAllSourcingRequestsByCompanyId(accessToken);
      const fxRateSupplier = await getExchangeRate(
        accessToken,
        ExchangeRateDto.FromEnum.Usd,
        ExchangeRateDto.ToEnum.KesSupplier
      );
      const fxRateOther = await getExchangeRate(
        accessToken,
        ExchangeRateDto.FromEnum.Usd,
        ExchangeRateDto.ToEnum.KesOther
      );
      const fxRateRwf = await getExchangeRate(
        accessToken,
        ExchangeRateDto.FromEnum.Usd,
        ExchangeRateDto.ToEnum.Rwf
      );
      const { pricingPlans } = await getPricingPlans(accessToken);
      setPlans(pricingPlans);
      if (user.user.role === RoleEnum.Admin) {
        setIsUserAdmin(true);
      }
      setCompanyData(data.company);
      setFxRateOther(fxRateOther.exchangeRate.value);
      setFxRateSupplier(fxRateSupplier.exchangeRate.value);
      setUsdToRwf(fxRateRwf.exchangeRate.value);
      setCurrency('ZAR');
      setCreditLimit(data.company.creditInformation?.creditLimit || 0);
      setSourcingRequests(sourcingReqs.sourcingRequests);
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  }

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  const finalSubmit = async (file?: File) => {
    setFormError(null);
    // setPaymentMethod(paymentMethod);

    try {
      const accessToken = await getAccessTokenSilently();
      const items = proformaInvoiceToPay?.items.map((item) => {
        return {
          name: item.name,
          quantity: item.quantity,
          unitPrice: item.price,
          total: item.total,
        };
      });

      if (isShippingIncluded) {
        items.push({
          name: 'Shipping',
          quantity: 1,
          unitPrice: proformaInvoiceToPay?.shippingEstimateAmount,
          total: proformaInvoiceToPay?.shippingEstimateAmount,
        });
      }

      const paymentRequest = await createPaymentRequest(accessToken, {
        amountInSecondaryCurrency: Number(proformaInvoiceToPay?.totalAmount),
        amountInSecondaryCurrencyCurrency:
          proformaInvoiceToPay.totalAmountCurrency as CreatePaymentRequestRequest.AmountCurrencyEnum,
        amount:
          Number(proformaInvoiceToPay?.totalAmountLocal) +
          Number(
            isShippingIncluded
              ? proformaInvoiceToPay?.shippingEstimateAmount
              : 0
          ),
        amountCurrency:
          proformaInvoiceToPay.totalAmountLocalCurrency as CreatePaymentRequestRequest.AmountCurrencyEnum,
        amountCredit: repaymentTerms.creditAmount,
        durationInDays: 30,
        items,
        type:
          paymentType === 'flexPay'
            ? CreatePaymentRequestRequest.TypeEnum.FlexPay
            : CreatePaymentRequestRequest.TypeEnum.PayNow,
        paymentMethod: CreatePaymentRequestRequest.PaymentMethodEnum.Transfer,
        supplierName: proformaInvoiceToPay?.supplierName,
        supplierId: null,
        termsAccepted: true,
      });

      if (file) {
        await uploadFile(
          accessToken,
          {
            type: 'PROOF_OF_USER_PAYMENT',
            paymentRequestId: paymentRequest.paymentRequestId,
          },
          file
        );
      }

      setIsOpen(false); // Close the modal
      if (repaymentTerms.balanceToPayNow === 0) {
        setIsSuccessModalOpen(true);
      } else {
        setIsSourcingRequestSuccessful(true);
        setModalType('pay');
        setPayMethod(file ? 'bank' : 'mobile');
      }
    } catch (err) {
      setIsOpen(false); // Close the modal
      setFormError('Failed to submit the payment request. Please try again.');
    } finally {
    }
  };

  const handleCloseSourcingSuccessModal = () => {
    if (modalType === 'sourcing') {
      setIsSourcingRequestSuccessful(false);
      setTabIndex(1);
      console.log('sourcing');
    } else {
      navigate('/dashboard/default');
    }
  };

  const calculateRepaymentTerms = (amount: number, duration: string) => {
    let feePercentage;
    let months;

    switch (duration) {
      case '30days':
        feePercentage = 0.035;
        months = 1;
        break;
      default:
        return {
          /* default values */
        };
    }

    const transactionFee = Math.ceil(
      roundToTwoDecimals(amount * feePercentage)
    );
    const totalRepay = amount + transactionFee;

    const dueDate = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);

    const dayOfWeek = dueDate.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      // If it's Sunday (0), add 1 day to make it Monday.
      // If it's Saturday (6), add 2 days to make it Monday.
      const daysToAdd = dayOfWeek === 0 ? 1 : 2;
      dueDate.setDate(dueDate.getDate() + daysToAdd);
    }

    const formattedDate = dueDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    const balanceToPayNow =
      Number(proformaInvoiceToPay?.totalAmountLocal) +
      Number(
        isShippingIncluded ? proformaInvoiceToPay?.shippingEstimateAmount : 0
      ) -
      amount;

    return {
      creditAmount: amount,
      totalRepay,
      transactionFee,
      balanceToPayNow: balanceToPayNow > 0 ? balanceToPayNow : 0,
      dueDate: formattedDate,
    };
  };

  const createRepaymentSchedule = (totalAmount: number, months: number) => {
    let schedule = [];
    const monthlyRepayment = Math.floor((totalAmount / months) * 100) / 100; // Round down to 2 decimal places
    let totalPaid = 0;
    let repaymentDate = new Date(); // Starting from today

    for (let i = 0; i < months; i++) {
      repaymentDate.setMonth(repaymentDate.getMonth() + 1); // Increment month
      let amountToPay = monthlyRepayment;

      // For the last payment, add any remaining balance
      if (i === months - 1) {
        amountToPay = totalAmount - totalPaid;
      }

      schedule.push({
        date: repaymentDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
        amount: amountToPay,
      });

      totalPaid += amountToPay;
    }

    return schedule;
  };

  const closeReviewModal = () => {
    setIsOpen(false);
  };

  const handleSubscriptionPlanSelect = async (
    planId: string,
    planName: string
  ) => {
    setSelectedPriceId(planId);
    setIsPlanSelectConfirmationModalOpen(true);
  };

  const handleItemNameChange = (index: number, value: string) => {
    const updatedItems = [...items];
    updatedItems[index]['name'] = value;
    setItems(updatedItems);
  };

  const handleItemProductCategoryChange = (
    index: number,
    value: SourcingItemDto.CategoryEnum
  ) => {
    const updatedItems = [...items];
    updatedItems[index]['category'] = value;
    setItems(updatedItems);
  };

  const handleItemQuantityChange = (index: number, value: number) => {
    const updatedItems = [...items];
    updatedItems[index]['quantity'] = value;
    setItems(updatedItems);
  };

  const addItemRow = () => {
    setItems([
      ...items,
      { name: '', quantity: 0, category: 'CONSTRUCTION_MATERIALS' },
    ]);
  };

  const removeItemRow = (index: number) => {
    if (items.length > 1) {
      const updatedItems = [...items];
      updatedItems.splice(index, 1);
      setItems(updatedItems);
    }
  };

  const isValidForm = () => {
    setError('');
    for (const item of items) {
      if (
        item.name.trim() === '' ||
        item.quantity === 0 ||
        item.category === ('' as any)
      ) {
        setError(t('items_error'));
        setIsSubmitLoading(false);
        return false;
      }
    }
    return true;
  };

  const handleSubmitSourcingRequest = async () => {
    const accessToken = await getAccessTokenSilently();
    setIsSubmitLoading(true);

    if (isValidForm()) {
      try {
        await createSourcingRequest(accessToken, { items });
        setIsSourcingRequestSuccessful(true);
        setModalType('sourcing');
        fetchData();
      } catch (error: any) {
        console.log({ error });
        setError(error?.message);
      }
      setIsSubmitLoading(false);
    }
  };

  const handleOpenProformaInvoices = (
    data: SourcingRequestProFormaInvoiceDto[]
  ) => {
    setProformaInvoices(null);
    setTimeout(() => {
      setIsViewInvoicesModalOpen(true);
      setProformaInvoices(data);
    }, 1000);
  };

  const handleOnRadioChange = (value: string) => {
    setPaymentType(value);
    setCreditAmount(0);
    setRepaymentTerms({
      creditAmount: 0,
      totalRepay: 0,
      balanceToPayNow: 0,
      transactionFee: 0,
      dueDate: null,
    } as RepaymentTermsType);
    setShowFlexPayForm(value === 'flexPay');
  };

  if (!companyData) {
    return <div>Loading...</div>; // or return a loading spinner component
  }

  // Check if country of the company is not Kenya, show coming soon
  if (companyData.country !== 'Kenya' && !companyData?.pricingPlan?.id) {
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Fragment>
          <Text fontSize='2xl' fontWeight='bold' textAlign='center' mb={2}>
            Pick the right plan for your business
          </Text>
          <Text fontSize='xl' textAlign='center' mb={6}>
            Enjoy a free 3-month trial for Standard and Premium plans
          </Text>
          <Flex gap={8} flexDirection={isTableLayout ? 'row' : 'column'}>
            {plans.map((price) => {
              return (
                <PricingBox
                  key={price.id}
                  id={price.id}
                  name={price.name}
                  features={price.features}
                  price={price.price}
                  isLoading={selectedPriceId === price.id && isSubscribeLoading}
                  onPlanSelect={handleSubscriptionPlanSelect}
                />
              );
            })}
          </Flex>

          {isPayModalOpen && (
            <PricingPayModal
              currency='RWF'
              isOpen={isPayModalOpen}
              onClose={() => setIsPayModalOpen(false)}
              paymentRequest={{
                amount: Math.ceil(
                  findSubscriptionPlanById(plans, selectedPriceId).price *
                    usdToRwf
                ),
                pricingPlanId: findSubscriptionPlanById(plans, selectedPriceId)
                  ?.id,
              }}
            />
          )}
          {isPlanSelectConfirmationModalOpen && (
            <PlanSelectConfirmationModal
              currency='RWF'
              isOpen={isPlanSelectConfirmationModalOpen}
              onClose={() => {
                setIsPlanSelectConfirmationModalOpen(false);
              }}
              pricingPlanName={
                findSubscriptionPlanById(plans, selectedPriceId)?.name
              }
              pricingPlanAmount={
                findSubscriptionPlanById(plans, selectedPriceId).price
              }
              paymentRequest={{
                amount: Math.ceil(
                  findSubscriptionPlanById(plans, selectedPriceId).price *
                    usdToRwf
                ),
                pricingPlanId: findSubscriptionPlanById(plans, selectedPriceId)
                  ?.id,
              }}
            />
          )}
        </Fragment>
      </Box>
    );
  }

  // Check if the kycStatus is PENDING
  if (
    companyData.kycStatus === KycStatusEnum.Pending ||
    companyData.kycStatus === KycStatusEnum.Declined
  ) {
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Card
          w={{ base: '100%', md: '50%' }}
          mb={{ base: '0px', '2xl': '20px' }}
          gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
          minH='365px'
          pe='20px'
        >
          <Text fontSize='xl' fontWeight='bold' mb={4}>
            {'International'}
          </Text>
          {companyData.kycStatus === KycStatusEnum.Pending && (
            <Text mb={4}>
              {
                'Your documents are being reviewed. We will notify you when your account has been activated.'
              }
            </Text>
          )}
          {companyData.kycStatus === KycStatusEnum.Declined && (
            <Text mb={4}>
              {
                'Your documents have been declined. Please contact us for more details.'
              }
            </Text>
          )}
        </Card>
      </Box>
    );
  }

  if (companyData.kycStatus !== KycStatusEnum.Approved) {
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Card
          w={{ base: '100%', md: '100%' }}
          mb={{ base: '0px', '2xl': '20px' }}
          gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
          minH='365px'
          pe='20px'
        >
          <GetStartedDirectPayments
            onContinue={() => {
              navigate('/dashboard/kyc');
            }}
          />
        </Card>
      </Box>
    );
  }

  // turn on kyc when user has subscribed
  if (
    companyData.kycStatus !== KycStatusEnum.Approved &&
    companyData.country !== 'Kenya' &&
    companyData?.pricingPlan?.id
  ) {
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Card
          w={{ base: '100%', md: '100%' }}
          mb={{ base: '0px', '2xl': '20px' }}
          gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
          minH='365px'
          pe='20px'
        >
          <GetStartedDirectPayments
            onContinue={() => {
              navigate('/dashboard/kyc');
            }}
          />
        </Card>
      </Box>
    );
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card
        w={{ base: '100%', md: '95%', xl: '95%' }}
        mb={{ base: '0px', '2xl': '20px' }}
        gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
        minH='365px'
        pe='20px'
      >
        <Text fontWeight='bold' fontSize='xl' mb={2}>
          International order
        </Text>

        <Text fontSize='sm' mb={4}>
          Available credit limit: {currency} {formatCurrency(creditLimit)}
        </Text>
        <Tabs index={tabIndex} onChange={handleTabChange}>
          <TabList>
            {companyData?.pricingPlan?.price !== 0 && (
              <Tab>Source products</Tab>
            )}
            {companyData?.pricingPlan?.price !== 0 && <Tab>Invoices</Tab>}

            <Tab>Pay a supplier</Tab>
          </TabList>

          <TabPanels>
            {companyData?.pricingPlan?.price !== 0 && (
              <TabPanel>
                <Text fontWeight='bold' fontSize='xl' mb={4}>
                  Enter the items to source
                </Text>

                {items.map((item, index) => {
                  return (
                    <Flex
                      gap={4}
                      key={index}
                      mb={8}
                      direction={['column', 'column', 'column', 'row']}
                      align={[
                        'flex-start',
                        'flex-start',
                        'flex-start',
                        'center',
                      ]}
                      justify='center'
                    >
                      <FormControl>
                        <Text mb={2} fontSize='sm' color='gray.500'>
                          Product category
                        </Text>
                        <Select
                          value={item.category}
                          placeholder='Select product category'
                          onChange={(e) =>
                            handleItemProductCategoryChange(
                              index,
                              e.target.value as SourcingItemDto.CategoryEnum
                            )
                          }
                          width={isTableLayout ? '250px' : undefined}
                        >
                          {Object.values(SourcingItemDto.CategoryEnum).map(
                            (category) => (
                              <option key={category} value={category}>
                                {category
                                  .replace('_', ' ')
                                  .replace(
                                    /^(\w)(.+)/,
                                    (match, p1, p2) =>
                                      p1.toUpperCase() + p2.toLowerCase()
                                  )}
                              </option>
                            )
                          )}
                        </Select>
                      </FormControl>

                      <FormControl>
                        <Text mb={2} fontSize='sm' color='gray.500'>
                          Enter item
                        </Text>
                        <Input
                          value={item.name}
                          onChange={(e) =>
                            handleItemNameChange(index, e.target.value)
                          }
                          width={isTableLayout ? '300px' : undefined}
                        />
                      </FormControl>

                      <FormControl>
                        <Text mb={2} fontSize='sm' color='gray.500'>
                          Quantity
                        </Text>
                        <Input
                          placeholder='0'
                          type='number'
                          onBlur={(e) =>
                            handleItemQuantityChange(
                              index,
                              e.target.value ? parseFloat(e.target.value) : 0
                            )
                          }
                          width='80px'
                        />
                      </FormControl>

                      <VStack
                        spacing={0}
                        align={[
                          'flex-start',
                          'flex-start',
                          'flex-start',
                          'flex-end',
                        ]}
                        width={['100%', '45%', '45%', 'auto']}
                        mt={[4, 4, 4, 0]}
                      >
                        <Box color='white'> _</Box>{' '}
                        <HStack spacing={2} ml={['0px', '0px', '0px', '10px']}>
                          <Button
                            onClick={() => addItemRow()}
                            colorScheme='blackAlpha'
                          >
                            +
                          </Button>
                          <Button
                            onClick={() => removeItemRow(index)}
                            colorScheme='blackAlpha'
                          >
                            X
                          </Button>
                        </HStack>
                      </VStack>
                    </Flex>
                  );
                })}

                <Flex justifyContent='flex-start' mb={4}>
                  {error && (
                    <Text color='red.500' mt={2}>
                      {error}
                    </Text>
                  )}
                </Flex>

                <Button
                  colorScheme={isSourcingRequestSuccessful ? 'green' : 'blue'}
                  onClick={handleSubmitSourcingRequest}
                  isLoading={isSubmitLoading}
                >
                  {isSourcingRequestSuccessful ? '✅ Submitted' : 'Submit'}
                </Button>
              </TabPanel>
            )}
            {companyData?.pricingPlan?.price !== 0 && (
              <TabPanel>
                {isTableLayout ? (
                  <TableComponent
                    handleOpenProformaInvoices={handleOpenProformaInvoices}
                    sourcingRequests={sourcingRequests}
                  />
                ) : (
                  <CardLayout
                    handleOpenProformaInvoices={handleOpenProformaInvoices}
                    sourcingRequests={sourcingRequests}
                  />
                )}
              </TabPanel>
            )}
            <TabPanel>
              <VStack spacing={4} align='start'>
                <Text mt={6} mb={2} fontWeight='bold'>
                  Payment
                </Text>
                <Box
                  p={4}
                  border='1px'
                  borderRadius='md'
                  borderColor='gray.200'
                  w={['100%', '100%', '100%', '60%']}
                >
                  <Flex justifyContent='space-between' mb={2}>
                    <Text fontWeight='bold' color='gray.700'>
                      Invoice amount
                    </Text>
                    <Text textAlign='right'>
                      {proformaInvoiceToPay?.totalAmountLocalCurrency}{' '}
                      {formatCurrency(proformaInvoiceToPay?.totalAmountLocal)}
                    </Text>
                  </Flex>
                  <Flex justifyContent='space-between' mb={2}>
                    <Text fontWeight='bold' color='gray.700'>
                      Shipping estimate
                    </Text>
                    <Text textAlign='right'>
                      {currency}{' '}
                      {formatCurrency(
                        isShippingIncluded
                          ? proformaInvoiceToPay?.shippingEstimateAmount
                          : 0
                      )}
                    </Text>
                  </Flex>
                  <HSeparator mb='10px' />
                  <Flex justifyContent='space-between'>
                    <Text fontWeight='bold' color='gray.700'>
                      Total amount
                    </Text>
                    <Text textAlign='right'>
                      {currency}{' '}
                      {formatCurrency(
                        Number(proformaInvoiceToPay?.totalAmountLocal) +
                          Number(
                            isShippingIncluded
                              ? proformaInvoiceToPay?.shippingEstimateAmount
                              : 0
                          )
                      )}
                    </Text>
                  </Flex>
                </Box>

                <PaymentOptionsBlock
                  currency={currency}
                  onChange={handleOnRadioChange}
                  radioGroupRef={radioGroupRef}
                  value={paymentType}
                  radioGroupWidth={radioGroupWidth}
                  totalAmount={
                    Number(proformaInvoiceToPay?.totalAmountLocal) +
                    Number(
                      isShippingIncluded
                        ? proformaInvoiceToPay?.shippingEstimateAmount
                        : 0
                    )
                  }
                  creditLimit={creditLimit}
                  shouldRenderFlexPay={creditLimit > 0 && isShippingIncluded}
                  isInternational={true}
                />

                {showFlexPayForm && (
                  <VStack
                    spacing={4}
                    align='start'
                    w={['100%', '100%', '85%', '45%']}
                  >
                    <FormControl>
                      <FormLabel whiteSpace='nowrap'>
                        Enter amount you want to pay on credit
                      </FormLabel>
                      <InputGroup>
                        <InputLeftAddon children={currency} />
                        <Input
                          type='number'
                          onChange={(e) =>
                            validateCreditAmountInput(
                              parseFloat(e.target.value)
                            )
                          }
                          max={200}
                          placeholder='0'
                          onBlur={(e) =>
                            setCreditAmount(
                              e.target.value ? parseFloat(e.target.value) : 0
                            )
                          }
                        />
                      </InputGroup>
                      {creditAmountError && (
                        <Text color='red.500' pt={'10px'} mb={4}>
                          {creditAmountError}
                        </Text>
                      )}
                    </FormControl>

                    <Text mt={6} mb={4} fontWeight='bold'>
                      Repayment terms
                    </Text>

                    <RepaymentTerms
                      currency={currency}
                      balanceToPayNow={repaymentTerms.balanceToPayNow}
                      paymentAmount={creditAmount}
                      totalRepay={repaymentTerms.totalRepay}
                      transactionFee={repaymentTerms.transactionFee}
                      dueDate={repaymentTerms.dueDate}
                    />

                    <Text w={['100%', '100%', '100%', '200%']}>
                      Click below to accept the repayment terms and conditions.
                    </Text>
                    <Text w={['100%', '100%', '100%', '200%']}>
                      By clicking below, you are concluding a legally binding
                      loan agreement with Symplifi Kenya Limited to repay the
                      loan based on the repayment terms and conditions above.
                    </Text>

                    <Checkbox
                      w={['100%', '100%', '100%', '200%']}
                      mt={6}
                      isChecked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                    >
                      I accept the terms and conditions of repayment
                    </Checkbox>
                  </VStack>
                )}

                {formError && (
                  <Text color='red.500' mb={4}>
                    {formError}
                  </Text>
                )}
                <Button colorScheme='blue' onClick={openReviewModal}>
                  Review
                </Button>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>

        {/* Modal */}
        <ConfirmationModal
          repaymentTerms={repaymentTerms}
          isOpen={isOpen}
          sendAmount={
            Number(proformaInvoiceToPay?.totalAmountLocal) +
            Number(
              isShippingIncluded
                ? proformaInvoiceToPay?.shippingEstimateAmount
                : 0
            )
          }
          currency={currency}
          totalAmount={
            Number(proformaInvoiceToPay?.totalAmountLocal) +
            Number(
              isShippingIncluded
                ? proformaInvoiceToPay?.shippingEstimateAmount
                : 0
            )
          }
          recipientCountry={recipientCountry}
          recipientName={recipientName}
          accountNumber={accountNumber}
          recipientAddress={recipientAddress}
          recipientSwiftCode={swiftCode}
          onClose={closeReviewModal}
          onConfirm={finalSubmit}
        />
        <ProofOfUserPaymentModal
          amount={
            repaymentTerms.creditAmount === 0
              ? Number(proformaInvoiceToPay?.totalAmountLocal) +
                Number(
                  isShippingIncluded
                    ? proformaInvoiceToPay?.shippingEstimateAmount
                    : 0
                )
              : repaymentTerms.balanceToPayNow
          }
          bankDetails={
            paymentMethod ===
            CreateDirectPaymentRequest.PaymentMethodEnum.BankTransfer
              ? flexPayBankDetails
              : mPesaDetails
          }
          currency={currency}
          isOpen={isSuccessModalOpen}
          onClose={() => {
            setIsSuccessModalOpen(false);
            navigate('/dashboard/default');
          }}
        />

        <SourcingSuccessModal
          isOpen={isSourcingRequestSuccessful}
          onClose={handleCloseSourcingSuccessModal}
          modalType={modalType}
          paymentMethod={payMethod}
        />

        {isViewInvoicesModalOpen && (
          <InvoicesModal
            isOpen={isViewInvoicesModalOpen}
            onClose={() => setIsViewInvoicesModalOpen(false)}
            proformaInvoices={proformaInvoices}
            setProformaInvoiceToPay={chooseProformaInvoiceToPay}
          />
        )}

        {isShippingModalOpen && (
          <ShippingModal
            isOpen={isShippingModalOpen}
            onClose={() => setIsShippingModalOpen(false)}
            proformaInvoiceToPay={proformaInvoiceToPay}
            handleActionPress={handleShipping}
          />
        )}
      </Card>
    </Box>
  );
}
