import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { ItemDto } from '../../../../generated-client/model/item-dto';
import { Item } from '../index';
import { formatCurrency, roundNumber } from '../../../../lib/utilities';
import { SupplierDto } from '../../../../generated-client/model/supplier-dto';
import { useTranslation } from 'react-i18next';
import { FaWhatsapp } from 'react-icons/fa';
import { InfoIcon } from '@chakra-ui/icons';
import SupplierDetailModal from './SupplierDetailModal';

type GenericEventListenerFn<T> = (
  index: number,
  field: keyof Item,
  value: T
) => void;

interface ItemsListProps {
  items: ItemDto[];
  handleItemNameChange: GenericEventListenerFn<string>;
  handleItemQuantityChange: GenericEventListenerFn<number>;
  handleItemUnitPriceChange: GenericEventListenerFn<number>;
  setSupplierId: (value: string) => void;
  addItemRow: () => void;
  removeItemRow: (index: number) => void;
  suppliers: SupplierDto[];
  supplierId?: string;
}

export const ItemsList: React.FC<ItemsListProps> = ({
  items,
  handleItemNameChange,
  suppliers,
  handleItemUnitPriceChange,
  handleItemQuantityChange,
  setSupplierId,
  addItemRow,
  removeItemRow,
  supplierId,
}) => {
  const { t } = useTranslation();
  const [supplier, setSupplier] = useState<SupplierDto>();
  const [isSupplierDetailModalOpen, setIsSupplierDetailModalOpen] =
    useState(false);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  useEffect(() => {
    if (supplierId) {
      handleSupplierChange({ target: { value: supplierId } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierId]);

  function handleSupplierChange({ target: { value: newSupplierId } }: any) {
    setSupplierId(newSupplierId);
    const targetSupplier = suppliers.find((s) => s.id === newSupplierId);
    setSupplier(targetSupplier);
  }

  function handleWhatsAppClick() {
    if (supplier) {
      const url = `https://wa.me/${supplier.whatsappContact}`;
      window.open(url, '_blank');
    }
  }

  return (
    <Fragment>
      <FormControl mt={6}>
        <SimpleGrid columns={1} spacing={4} mb={4}>
          <FormControl mt={6}>
            <FormLabel fontWeight='bold'>{t('select_supplier')}</FormLabel>
            <SimpleGrid columns={1} spacing={4} mb={4}>
              <FormControl>
                <Flex
                  direction={{
                    base:
                      supplier && supplier.whatsappContact ? 'column' : 'row',
                    md: 'column',
                    lg: 'row',
                  }}
                  alignItems='left'
                  gap={4}
                >
                  <Select
                    placeholder={t('select_supplier')}
                    onChange={handleSupplierChange}
                    defaultValue={supplierId}
                    w={{ base: '100%', md: '280px' }} // Matching width as the first Select
                    maxW='280px'
                  >
                    {suppliers.map((supplier) => (
                      <option
                        key={supplier.id}
                        value={supplier.id || supplierId}
                      >
                        {supplier.friendlyName}
                      </option>
                    ))}
                  </Select>

                  {supplier && !supplier.whatsappContact ? (
                    <Button
                      ml='-10px'
                      backgroundColor='#E7F2FF'
                      color={textColorPrimary}
                      _hover={{ bg: '#E7F2FF' }}
                      style={{ transform: 'scale(0.9)' }}
                      maxWidth={20}
                      onClick={() => setIsSupplierDetailModalOpen(true)}
                    >
                      <InfoIcon />
                    </Button>
                  ) : (
                    <Flex gap={2}>
                      {supplier && supplier.whatsappContact && (
                        <Button
                          leftIcon={
                            <Box
                              as='span'
                              p='1'
                              bg='green.500'
                              borderRadius='full'
                            >
                              <FaWhatsapp
                                color='white'
                                style={{ fontSize: '80%' }}
                              />
                            </Box>
                          }
                          ml='-10px'
                          maxW='200px'
                          backgroundColor='#E7F2FF'
                          color={textColorPrimary}
                          onClick={handleWhatsAppClick}
                          _hover={{ bg: '#E7F2FF' }}
                          style={{ transform: 'scale(0.9)' }}
                        >
                          {t('contact_supplier')}
                        </Button>
                      )}

                      {supplier && (
                        <Button
                          ml='-10px'
                          backgroundColor='#E7F2FF'
                          color={textColorPrimary}
                          _hover={{ bg: '#E7F2FF' }}
                          style={{ transform: 'scale(0.9)' }}
                          maxWidth={20}
                          onClick={() => setIsSupplierDetailModalOpen(true)}
                        >
                          <InfoIcon />
                        </Button>
                      )}
                    </Flex>
                  )}
                </Flex>
              </FormControl>
            </SimpleGrid>
          </FormControl>
        </SimpleGrid>

        <FormLabel fontWeight='bold'>{t('enter_items_to_order')}</FormLabel>

        {items.map((item, index) => (
          <Flex
            key={index}
            mb={3}
            direction={['column', 'column', 'column', 'row']}
            align={'flex-start'}
            // justify='center'
          >
            <Box
              w={['100%', '100%', '100%', '45%']}
              mb={[4, 4, 4, 0]}
              mr={[0, 0, 0, 4]}
            >
              <VStack alignItems='flex-start' spacing={0}>
                <Text mb={2} fontSize='sm' color='gray.500'>
                  {t('product')}
                </Text>
                <Input
                  placeholder=''
                  value={item.name}
                  onChange={(e) =>
                    handleItemNameChange(index, 'name', e.target.value)
                  }
                />
              </VStack>
            </Box>
            <Box
              w={['100%', '45%', '45%', '15%']}
              mb={[4, 4, 4, 0]}
              mr={[0, 2, 2, 2]}
            >
              <VStack alignItems='flex-start' spacing={0}>
                <Text mb={2} fontSize='sm' color='gray.500'>
                  {t('quantity')}
                </Text>
                <Input
                  placeholder='0'
                  defaultValue={item.quantity || 0}
                  // maxW="100px"
                  type='number'
                  onBlur={(e) =>
                    handleItemQuantityChange(
                      index,
                      'quantity',
                      e.target.value ? parseFloat(e.target.value) : 0
                    )
                  }
                />
              </VStack>
            </Box>

            <VStack
              spacing={0}
              align={['flex-start', 'flex-start', 'flex-start', 'flex-end']}
              width={['100%', '45%', '45%', 'auto']}
              mt={[4, 4, 4, 0]}
            >
              <Box color='white'> _</Box> {/* Empty box to take up the space */}
              <HStack spacing={2} ml={['0px', '0px', '0px', '10px']}>
                <Button onClick={() => addItemRow()} colorScheme='blackAlpha'>
                  +
                </Button>
                <Button
                  onClick={() => removeItemRow(index)}
                  colorScheme='blackAlpha'
                >
                  X
                </Button>
              </HStack>
            </VStack>
          </Flex>
        ))}
      </FormControl>

      {supplier && (
        <SupplierDetailModal
          isOpen={isSupplierDetailModalOpen}
          onClose={() => setIsSupplierDetailModalOpen(false)}
          handleWhatsAppClick={handleWhatsAppClick}
          textColorPrimary={textColorPrimary}
          supplier={supplier}
        />
      )}
    </Fragment>
  );
};
